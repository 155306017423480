import React from 'react'
import Header from '../components/Header'
// import Recipes from '../components/Recipes'
import Banner2 from '../images/banner2.jpg'
import Banner4 from '../images/banner4.jpg'
import About from '../components/About'
import Button from '../components/Button'
import Referral from '../components/Referral'
import Training from '../components/Training'

function Home() {
  return (
    <main className='w-full flex flex-col'>
      <Header 
      title={
        <p>  <br></br></p>
      }

      type='Home'
      />
 <center>
        <Button
  title='TRADE NOW'
  href='https://wa.me/2349072773575'
  containerStyle='bg-blue-900 w-[25%] md:block bg-blue-900 border border-white text-white hover:bg-white hover:text-slate-700 rounded-full min-w-[130px]'
/>

        </center>
      <section id='recipes' className='md: max-w-[1440px] mx-auto px-4 md:px-20'>
        <div>
        <img src={Banner2} alt='JoshExchange' className=''/>
        
        </div>
      
        <div>
        <img src={Banner4} alt='JoshExchange' className=''/>
        </div>
        <center>
        <Button
  title='TRADE NOW'
  href='https://wa.me/2349072773575'
  containerStyle='bg-blue-900 w-[25%] md:block bg-blue-900 border border-white text-white hover:bg-white hover:text-slate-700 rounded-full min-w-[130px]'
/>

        </center>
      
      </section>
      <section id='about'>
        <About />
      </section>
      <section>
        <Referral />
      </section>

      <section>
        <Training />
      </section>

      
    </main>
  )
}

export default Home