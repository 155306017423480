import React from 'react';

function Button({ isDisabled, btnType, containerStyle, title, rightIcon, handleClick, href, url }) {
  if (href || url) {
    return (
      <a
        href={href || url}
        className={`custom-btn ${containerStyle}`}
        onClick={handleClick}
      >
        <span className='flex-1'>{title}</span>
        {rightIcon && <div className='relative w-6 h-6'>{rightIcon}</div>}
      </a>
    );
  } else {
    return (
      <button
        disabled={isDisabled ?? false}
        type={btnType || 'button'}
        className={`custom-btn ${containerStyle}`}
        onClick={handleClick}
      >
        <span className='flex-1'>{title}</span>
        {rightIcon && <div className='relative w-6 h-6'>{rightIcon}</div>}
      </button>
    );
  }
}

export default Button;
