import React, { useState } from 'react';
import jlo from '../images/jlo.png'
import {HiMenuAlt3} from 'react-icons/hi'
import {AiOutlineClose} from 'react-icons/ai'
import Button from './Button';




function NavBar() {
const [open, setOpen] = useState(false);

  return (
  <header className='w-full fixed bg-blue-900 z-10 opacity-90'>
    <nav className='flex w-full py-2 md:py-3 px-4 md:px-20 items-center justify-between'>
      <a href='/' className='flex text-white justify-center items-center text-lg cursor-pointer'>
        <img src={jlo} alt='Shugadel' className='hidden md:block w-8 h-8 lg:w-14 lg:h-14'/>
        Josh<span className='text-orange-200'>Exchange</span>
      </a>
      <ul className='hidden md:flex text-white gap-6'>
        <li>
          <a href='/'> Home</a>
        </li>
        <li>
          <a href='/#recipes'> Explore</a>
        </li>
        <li>
          <a href='/#about'> About</a>
        </li>

      </ul>
      <Button
  title='Contact Us'
  href='https://wa.me/2349072773575'
  containerStyle='hidden md:block bg-transparent border border-white text-white hover:bg-white hover:text-slate-700 rounded-full min-w-[130px]'
/>


      

      <button className='block md:hidden text-white text-xl'
      onClick={()=> setOpen(prev => !prev)}>
        {open ? <AiOutlineClose /> :
        <HiMenuAlt3 />}
      </button>
      <div className={`${open ? "flex" : "hidden"} bg-black flex-col w-full px-4 pt-16 pb-10 text-white gap-6
       text-[14px]`}>
                <a href="/">Home</a>
                <a href="/#recipes">Explore</a>
                <a href="/#about">About</a>
                <a href="https://wa.me/2349072773575
">Contact</a>
            </div>

    </nav>
  </header>
  );
}

export default NavBar;
