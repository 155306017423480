import React from 'react';
import { Banner1, Banner8, Banner3, Banner5, Banner6, Banner7} from '../images';


const images = [Banner1, Banner8, Banner3, Banner5, Banner6, Banner7 ];

function Header( {title , image, type}) {

 

  return (
    <div className='w-full h-[100vh]'>
      <div className='relative w-full h-full'>
      <img src={image ?? images[Math.floor(Math.random() * images.length)]} alt='random' className='w-full h-full object-cover' />
      </div>

      <div className='absolute w-full h-full bg-gradient-to-t from-slate-900 to-transparent top-0 z-8 justify-center pt-40 2xl:pt-20 px-4'>
        <h1 className='text-white font-bold text-center text-4xl md:text-5xl'>{title}</h1>
        {/* {
          type && (
            <p className='text-sm mt-4 text-center text-green-500 font-bold bg-[#00000090] px-6 py-4 rounded-full'>
              Welcome to enjoying the best of delicacies globally 
              <br className='hidden md:block' /> 
              Satisfy your tastes buds with our amazing recipes
            </p>
          )
            
      
        } */}

      </div>
     
    </div>
  );
}

export default Header;
