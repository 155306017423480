import React from 'react';
import refer from '../images/refer.jpg'
import Button from './Button';

function Referral() {
  return (
    <div className='py-10 h-auto'>
    <div className='flex sm:flex-row flex-col items-center md:gap-6 gap-12 px-10 max-w-6xl mx-auto'>
      <div>
        <div className='w-full md:w-[400px] h-full'> {/* Adjusted width */}
          <img src={refer} alt='profile' className='object-cover rounded-xl h-[300px] w-full md:h-full filter' /> {/* Adjusted image width */}
        </div>
      </div>
      <div className='p-2'>
        <div className='text-white my-3'>
          <h1 className='text-4xl font-semibold mb-5 text-orange-400'>Refer and Earn</h1>
          <p1 className='text-justify leading-7 w-11/12 mx-auto'><span className='font-bold text-xl text-blue-900 '> Rules</span> <br />
           Ask us for your Josh exchange referral code so when you refer you get registered under our referral program for as many people who comes with your code and trades you get credited immediately after they trade <br />
           <span  className='font-bold text-xl text-blue-900 '>NOTE</span> <br />Min before payout :3 active customers who trade with us
          </p1>
          
        </div>
        <Button
  title='Get Started'
  href='https://wa.me/2349072773575'
  containerStyle='bg-blue-900 w-[25%] md:block bg-blue-900 border border-white text-white hover:bg-white hover:text-slate-700 rounded-full min-w-[130px]'
/>
      </div>
    </div>
  </div>
  );
}

export default Referral;
