import React from 'react';
import vibes from '../images/vibes.jpg'

function About() {
  return (
    <div className='py-10 h-auto'>
    <div className='flex sm:flex-row flex-col-reverse items-center md:gap-6 gap-12 px-10 max-w-6xl mx-auto'>
      <div>
        <div className='w-full md:w-[400px] h-full'> {/* Adjusted width */}
          <img src={vibes} alt='profile' className='object-cover rounded-xl h-[300px] w-full md:h-full filter' /> {/* Adjusted image width */}
        </div>
      </div>
      <div className='p-2'>
        <div className='text-white my-3'>
          <h1 className='text-4xl font-semibold mb-5 text-orange-400'>ABOUT US</h1>
          <p1 className='text-justify leading-7 w-11/12 mx-auto'>
            Launched in the year 2018, Josh Exchange was innovated by Oji Joshua Ekene, aka Orgee V👀bez who is a graduate of Delta State University, holding a B.Sc degree in Biochemistry. This brand was developed for the purpose of connecting people from different diversity to experience good trading experience and has over the years delivered robust customer satisfaction techniques that have served all customers without having issues. Josh exchange connects hearts and also promotes the spirit of oneness with our customers and maintains a very high standard relationship with them.
          </p1>
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default About;
