import React from 'react';
import learn from '../images/learn.webp'
import Button from './Button';

function Training() {
  return (
    <div className='py-10 h-auto'>
      <div className='flex sm:flex-row flex-col-reverse items-center md:gap-6 gap-12 px-10 max-w-6xl mx-auto'> {/* Reversed flex direction for mobile view */}
        <div className='p-2 md:order-1'> {/* Changed order for desktop view */}
          <div className='text-white my-3'>
            <h1 className='text-4xl font-semibold mb-5 text-orange-400'>Be Our Student</h1>
            <p className='text-justify leading-7 w-11/12 mx-auto'>
              Learn how to trade professionally with us. We will teach you all you need to set you up for financial independence. <br />
              <span className='font-bold text-xl text-blue-900'>NOTE</span> <br /> T & C Apply.
              

            </p>
          </div>
          <Button
  title='Get Started'
  href='https://wa.me/2349072773575'
  containerStyle='bg-blue-900 w-[25%] md:block bg-blue-900 border border-white text-white hover:bg-white hover:text-slate-700 rounded-full min-w-[130px]'
/>
        </div>
        <div>
          <div className='w-full md:w-[400px] h-full'> {/* Adjusted width */}
            <img src={learn} alt='profile' className='object-cover rounded-xl h-[300px] w-full md:h-full filter' /> {/* Adjusted image width */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Training;
